.button {
  position: relative;
  display: block;
  background: transparent;
  height: 80px;
  width: 420px;
  line-height: 32px;
  text-align: center;
  font-size: 24px;
  text-decoration: none;
  color: #000;
  border-radius: 100px;
  margin: 40px 0;
  box-sizing: border-box;
  border: 0te;
  padding: 3px;
  transition: all ease .3s;

  font-weight: 700;
  transition: 0.3s ease-in-out all;
  z-index: 3;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

 .beforetext {
  margin-right: 10px !important;
  /* margin-top: -5px !important; */

}
 .imgafter {
  margin-left: 10px !important;
  /* margin-top: -5px !important; */
}
.button .button__inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100px;
  position: relative;
  /* padding: 0 50px; */
}
.button .button__inner span{
display: flex;
justify-content: center;
align-items: center;
}
.button:hover{
  border: 2px solid #F8F8F8;
/* box-shadow: 0px 0px 9px #EBC43E; */
border: 2px solid white;


filter: drop-shadow(0px 2px 25px rgba(235, 196, 62, 0.5));
}



.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  /* height: 100%; */
  position: fixed;
  /* width: 100%; */
  
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: var(--primary-color);
  display: inline-block;
  height: 10px;
  margin: 10px;
  width: 10px;
  border-radius: 50%;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% { background-color: var(--primary-color); transform: scale(1); }
  50% { background-color: var(--primary-color); transform: scale(1.3); }
  100% { background-color: var(--primary-color); transform: scale(1); }
}


@media screen and (max-width: 1350px) {
  .buttonBox .button{
    font-size: 18px !important;


  }


  .button {
    width: 360px;
    font-size: 21px;
    height: 72px;
    line-height: 21px;
    /* margin: 20px 0; */
  }


}


@media screen and (max-width: 998px) {
  .button {
    width: 330px;
    font-size: 18px;
    height: 64px;
    line-height: 64px;
  }
  .button::after,
  .button::before {
    height: 32px;
    width: 300px;
  }
  .button::after {
    top: 32px;
  }
}


@media screen and (max-width: 768px) {
  .button {
    width: 240px;
    font-size: 16px;
    height: 48px;
    line-height: 18px;
    margin: 20px 0;
  }
  .button::after,
  .button::before {
    height: 32px;
    width: 300px;
  }
  .button::after {
    top: 32px;
  }
  .beforetext {
    margin-right: 10px !important;
    margin-top: 0px !important;
    width: 20px;
  
  }
   .imgafter {
    margin-left: 10px !important;
    margin-top: -5px !important;
  }
}
