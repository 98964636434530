.product__outer {
  /* margin: 20px; */
  /* margin-top: 30px;
  margin-left: 20px; */
}

.tooltip {
  position: relative;
  display: inline-block;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.724);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  top: -43px;
  left: -20px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  font-size: 14px;
  visibility: visible;
}



.product__outer .product__item {
  background-image: url("../../assets/images/CardBG.png");
  background-size: cover;
  /* width: 349px; */
  height: 370px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  position: relative;
  place-items: center;
  cursor: pointer;
}

.product__outer .product__item .product__image {
  transition: 0.3s ease-in-out margin;
  height: auto;
  width: 100%;
}
.product__outer .product__item .product__image img {
  transition: 0.3s ease-in-out transform;
  width: 100%;
}
.product__outer .product__item .product__info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100px;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  padding: 0 20px;
  padding-bottom: 20px;
  background: rgba(199, 58, 51, 0.8);


}
.product__outer .product__item .product__info .item__company {
  /* font-family: "Trap"; */
  font-style: normal;
  font-weight: 900;
  font-size: 16.4988px;
  /* line-height: 100%; */
  /* min-height: 25px; */


  margin: 0;
  /* padding-bottom: 10px; */
}
.product__outer .product__item .product__info .item__name {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #fbb501;
  margin: 0;
  padding: 5px 0;
  /* margin: 15px 0; */
}
.price {
  /* font-family: "Trap"; */
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  padding-top: 10px !important;

  line-height: 100%;
}
.discoundStatus {
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 18px */

  color: #fcd449;
}

@media screen and (max-width: 1310px) {
  .product__outer .product__item .product__info .item__company {
    font-size: 16.4988px;
  }
  .product__outer .product__item .product__info .item__name {
    font-size: 13px;
    padding: 5px 0;

    /* margin: 15px 0; */
  }
  .price {
    font-size: 17px;
    /* line-height: 100%; */
  }
}
.product__outer .product__item .product__info::before {

  z-index: 1;
  backdrop-filter: blur(4px);
  transform: rotateZ(0deg);
  position: absolute;
  content: "";
  left: -50px;
  height: 100px;
  width: 200%;
  top: 0px;
}
.product__outer .product__item .product__info .product__info__inner {
  z-index: 3;
  position: relative;
  padding-top: -20px;
}
.product__outer:hover .product__item .product__image {
  margin-bottom: 30px;
}
.product__outer:hover .product__item .product__image img {
  transform: scale(1.04);
}
.product__outer .button__container {
  text-align: center;
  position: relative;
  z-index: 8;
  width: 100%;
}
.product__outer .button__container .button {
  margin: auto;
  transform: translateY(-50%);
  width: 180px;
  font-size: 18px;
  height: 50px;
  line-height: 52px;
}
.product__outer .button__container .button::after,
.product__outer .button__container .button::before {
  border-color: #000;
  width: 200px;
  height: 26px;
}
.product__outer .button__container .button__inner::after,
.product__outer .button__container .button__inner::before {
  background-color: #000;
}
.product__outer .button__container .button::after {
  top: 25px;
}
.product__outer .button__container .button:hover::after,
.product__outer .button__container .button:hover::before {
  border-color: #fff;
}
.discoundedPrice {
  display: flex;
  align-items: center;
  justify-self: start;
  gap: 8px;
}
.oldPrice {
  font-weight: 800;
  font-size: 18px;
  line-height: 100%;
  text-decoration-line: line-through;
  color: #c4c4c4;
}
.discoudedPrice {
  font-weight: 800;

  font-size: 32px;
  line-height: 100%;
}
.discoundStatus {
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 18px */

  color: #fcd449;
}
.discoudedPrice {
  font-weight: 800;

  font-size: 24px;
  line-height: 100%;
}
@media screen and (max-width: 1100px) {
  .product__outer .product__item .product__info .item__company {
    font-size: 16px;

  }
  .product__outer .product__item .product__info .item__name {

padding: 5px 0;

  }

}
@media only screen and (max-width: 768px) {

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.724);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
  
    /* Position the tooltip */
    position: absolute;
    top: auto;
    bottom: 32px;
    left: -20px;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    font-size: 8px;
    visibility: visible;
  }

  
  .product__outer .product__item .product__info .product__info__inner {
    z-index: 3;
    position: relative;
    padding-top: 0px;
  }
  .product__outer {
    margin: 0;
    /* margin-left: 20px; */
  }
  .product__outer .product__item {
    width: 100%;
    height: 235px;
    border-radius: 5px;

  }
  .product__outer .product__item .product__image {
    transition: 0.3s ease-in-out margin;
    height: auto;
    width: 90%;
  }
  .product__outer .product__item .product__image img {
    transition: 0.3s ease-in-out transform;
    width: 100%;
  }

  .product__outer .product__item .product__info .item__company {
    font-weight: 900;
    font-size: 11px;
    /* line-height: 100%; */
    margin: 0;
    min-height: auto;
  }

  .product__outer .product__item .product__info .item__name {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 110%;
    color: #fbb501;
    margin: 0;
    padding:0px 0;

    /* margin: 15px 0; */
  }
  .price {
    /* font-family: "Trap"; */
    font-style: normal;
    font-weight: 800;
    /* font-size: 20px; */

    line-height: 100%;
  }

  .discoundedPrice {
    display: flex;
    align-items: center;
    justify-self: start;
    gap: 12px;
  }
  .oldPrice {
    font-weight: 800;
    font-size: 10px;
    line-height: 100%;
    text-decoration-line: line-through;
    color: #c4c4c4;
  }
  .discoudedPrice {
    font-weight: 800;

    font-size: 13px;
    line-height: 100%;
  }
  .discoundStatus {
    font-weight: 600;
    font-size: 6px;
    line-height: 100%;
    /* identical to box height, or 18px */

    color: #fcd449;
  }

  .product__outer .button__container .button {
    margin: auto;
    transform: translateY(-50%);
    width: 130px;
    padding: 7px;
    font-size: 13px;
    height: 48px;
    line-height: 52px;
  }

  .product__outer .product__item .product__info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 84px;
    display: flex;
    align-items: center;
    /* overflow: hidden; */
    padding: 12px;
    padding-bottom: 30px;
  }
}
