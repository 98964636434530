.loaderl {
  position: fixed;
  inset: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  z-index: 3002;
  background-color: rgb(0, 0, 0);
}
.loader .loader__inner {
  padding: 20px 40px;
  height: 200px;
  width: 380px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaders {
 
  
  border-radius: 50%;
  width: 84px;
  height: 84px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
 }
 
 /* Safari */
 @-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
 }
 
 @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
 }
