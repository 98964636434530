footer {
  width: 100%;
  /* background-color: #5a1bff; */
  position: relative;
  padding: 60px 0;
  padding-top: 160px;
  /* margin-top: -100px; */
  padding-bottom: 0;
  z-index: 0;
  /* min-height: 700px; */
  font-family: "Trap";
}
footer .foot__image {
  position: absolute;
  right: 0;
  top: 100px;
  max-height: 70%;
  z-index: 2;
  user-select: none;
  pointer-events: none;
}
.new {
  color: #c4c4c4;
  max-width: 670px;
}
.footerBottom {

 
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Trap";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  border-top: 1px solid #555;
  margin-bottom: 40px;
  padding-top: 40px;
}
.footerBottom a {
  transition: all ease 0.3s;
}
.footerBottom a:hover {
  color: #fa524a;
}
.left {
  display: flex;
  gap: 48px;
  position: relative;
  z-index: 20;
}
.left a {
  text-decoration: none;
  color: var(--text-secondry-color);
}
.formWrapper .test {
  max-width: 920px;
}
.formBox {
  margin-top: 70px;
  max-width: 560px;
}
.inputBox small {
  color: #fa524a;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
}
.footerf {
  margin-bottom: 60px;
}
.closeBtn {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}
.wrapperYelloe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.inputBox input::-webkit-input-placeholder {
  font-family: "Trap";
}

.inputBox input:-ms-input-placeholder {
  font-family: "Trap";
}

.inputBox input:-moz-placeholder {
  font-family: "Trap";
}

.inputBox input::-moz-placeholder {
  font-family: "Trap";
}
.inputBox input {
  position: relative;
  z-index: 20;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-bottom: 2px solid #c4c4c4;
  background: transparent;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  padding: 12px 0;
}
.inputBox .input{
  margin-bottom: 30px;


}
.inputBox input:focus-visible {
  box-shadow: none;

}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: none !important;
}

/* input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
} */

/* .inputBox input:focus, .inputBox input:active {
  background-color: transparent;
} */

footer .copyright {
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
}
footer .builtwith {
  width: 300px;
  background-color: rgba(255, 255, 255, 0.202);
  backdrop-filter: blur(6px);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  padding-bottom: 10px;
  margin: auto;
}
footer .builtwith p {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
footer .builtwith .partner__flex {
  justify-content: center;
  display: flex;
}
footer .builtwith .partner__flex .partner__flex__item {
  margin: 10px;
  height: 70px;
}
footer .builtwith .partner__flex .partner__flex__item img {
  height: 100%;
}

footer .footlogo {
  height: 160px;
  margin: 30px auto;
}
footer .footlogo img {
  height: 100%;
}
footer p {
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
}

.fBtn .button {
  width: 390px;
}
.checkbox {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  font-size: 22px;
  font-family: "Trap";
  margin-top: 30px;
  margin-bottom: 10px;
}
.checkbox input {
  width: 24px;
  height: 24px;
  font-family: "Trap";
  transform: scale(1.3);
  background-color: transparent;
  color: #fa524a;
  margin: 0;
}
.ffLogos {
  /* margin-bottom: 10px; */
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ffLogos a{
  margin-top: 6px;
}
.testf{
  font-size: 18px;

}



@media screen and (max-width: 1390px) {
  .formWrapper .test {
    max-width: 600px;
    font-size: 60px !important;
  }
  footer .foot__image {
    position: absolute;
    right: 0;
    top: 110px;
    max-height: 70%;
    z-index: 2;
  }
}
@media screen and (max-width: 999px) {
  footer {
    padding: 0px 0;
    padding-top: 40px;
  }
  footer .footlogo {
    height: 90px;
    margin: 30px auto;
  }
  .new {
    color: #c4c4c4;
    max-width: 470px;
  }

  footer .foot__image {
    top: 30px;
    max-height: 50%;
    z-index: -1;
  }
}


@media screen and (max-width: 768px) {
  .testf{
    font-size: 12px;
  
  }
  .ffLogos{
    display: none;
  }
  .checkbox {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    font-size: 12px;
    font-family: "Trap";
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: left;
    font-family: "Trap";
  }
  .checkbox input {
    max-width: 10px;
    height: 10px;
    font-family: "Trap";
    transform: scale(2);
    background-color: transparent;
    color: #fa524a;
    margin: 0;
  }
  .ffLogo {
    margin-bottom: 10px;
    
  }
  .ffLogo img {
    width: 155px;
  }
  footer .foot__image {
    opacity: 0.6;
    display: none;
  }
  footer {
    text-align: center;
  }
  footer .footlogo {
    display: flex;
    justify-content: center;
    height: 89px;
  }
  footer p {
    font-size: 20px;
  }
  .fBtn .button {
    margin: 35px auto;
    font-size: 14px;
    width: 250px;
  }

  .copyr {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
  }
  .formWrapper .formtex {
    font-size: 34px !important;
  }

  .footerBottom {
    margin-bottom: 20px;
    padding-top: 20px;
    /* margin: 20px 0; */
    display: flex;
    justify-content: space-between;
    font-family: "Trap";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
  }
  .left {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */

  }
  .left a {
    text-decoration: none;
    color: var(--text-secondry-color);
  }
  .formWrapper {
    padding-top: 30px;
  }
  .formBox {
    margin-top: 40px;
    max-width: 560px;
  }
  .inputBox small {
    color: #fa524a;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
  }
  .formBtnBox {
    width: 100%;
    text-align: center;
  }
  .formBtnBox .button {
    margin: 0 auto;
    margin-top: 40px;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    width: 240px;
  }
  .formBtnBox .button .button__inner {
    padding: 12px auto;
  }
  .footerf {
    margin-bottom: 60px;
  }
  .closeBtn {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
  }
  .wrapperYelloe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .inputBox input {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    border-bottom: 2px solid #c4c4c4;
    background: transparent;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    padding: 8px 0;
    margin-bottom: 10px;
  }
  .new {
    color: #c4c4c4;
    max-width: 670px;
    font-size: 14px;
    font-weight: 400;
    max-width: 353px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 456px) {
  footer .foot__image {
    opacity: 0.6;
    display: none;
  }
}

@media screen and (max-width: 456px) {
}
