nav {
  z-index: 999;
  position: fixed;
  background-color: var(--primary-color);
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  display: flex;
}
nav .logo {
  background-color: #fff;
  text-align: center;
  padding: 16px 32px;
}
.muted{
  margin-right: 20px;
  cursor: pointer;
}
.muted img{
  width: 30px;
}
nav .logo img {
  height: 40px;
}
nav .logo .mobilelogo {
  display: none;
  width: 70px;
}

.isConnected {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.climedStatus{
  position: absolute;
  top: -5px;
  right: -5px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 1px solid #fff ;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: #C73A33;
}

.chain {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  padding: 7px 18px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

@media screen and (max-width: 456px) {
  nav .logo .mobilelogo {
    display: block;
  }
  nav .logo .desktoplogo {
    display: none;
  }
}

nav .nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 32px;
  width: 100%;
}
nav .nav__container ul.mainnav {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
nav .nav__container ul.mainnav li {
  position: relative;
}
nav .nav__container ul.mainnav li .soon {
  position: absolute;
  font-size: 9px;
  right: 20px;
  bottom: -14px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  padding: 2px 5px;
}
nav .nav__container ul.mainnav li > a {
  padding: 8px 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
}
nav .nav__container ul.mainnav .button {
  margin: auto;
  width: 100%;
  font-size: 21px;
  height: 52px;
  line-height: 52px;
}
nav .nav__container ul.mainnav .button:before,
nav .nav__container ul.mainnav .button:after {
  height: 26px;
  width: 100%;
}
nav .nav__container ul.mainnav .button::before {
  top: 0;
}
nav .nav__container ul.mainnav .button::after {
  top: 25px;
}
@media screen and (max-width: 756px) {
  .muted{
    margin-right: 10px;
    cursor: pointer;
  }
  nav .connect .button__outer {
    width: 130px!important;
}
.muted img{
  width: 25px;
}



}

@media screen and (max-width: 456px) {
  nav .nav__container ul.mainnav .button {
    width: 140px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }
  nav .nav__container ul.mainnav .button:before,
  nav .nav__container ul.mainnav .button:after {
    width: 140px;
    height: 20px;
  }
  nav .nav__container ul.mainnav .button:after {
    top: 19px;
  }
}

nav .nav__container .profile {
  position: relative;
}
nav .nav__container .profile .profileicon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
nav .nav__container .profile .profileicon img {
  height: 100%;
  width: 100%;
}
.ProfileInfo {
  padding-top: 30px;
  position: absolute;
  right: 0px;
}
nav .nav__container .profile .profile__info {
  text-align: center;
  width: 240px;

  border: 1px solid #d5d5d5;
  border-radius: 12px;
  padding: 6px;
  background-color: #000;
  /*  */
  display: none;
  /*  */
  z-index: 10;
  top: 50px;
}
nav .nav__container .profile .profile__info img {
  height: 40px;
  width: 40px;
  margin: 10px auto;
}
nav .nav__container .profile .profile__info p {
  text-align: left;
}
nav .nav__container .profile .profile__info .disconnect .button {
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  height: 59px;
  color: #000;
}

nav .nav__container .profile .profile__info .disconnect .button:hover::after,
nav .nav__container .profile .profile__info .disconnect .button:hover::before {
  background-color: transparent;
}
nav
  .nav__container
  .profile
  .profile__info
  .disconnect
  .button
  .button__inner::before,
nav
  .nav__container
  .profile
  .profile__info
  .disconnect
  .button
  .button__inner::after {
  background-color: #fff;
}
nav .nav__container .profile .profile__info .disconnect .button::before,
nav .nav__container .profile .profile__info .disconnect .button::after {
  width: 140px;
  height: 20px;
  background-color: #fff;
  border-color: #fff;
}
nav .nav__container .profile .profile__info .disconnect .button::after {
  top: 20px;
}
nav .nav__container .profile:hover .profile__info {
  display: block;
}
.connect .button__outer {
  width: 220px !important;
}
/*  */
.innerbox {
  background-color: #333;
  border-radius: 9px;
  padding: 10px;
  margin-bottom: 12px;
}
.innerbox a {
  color: #d5d5d5;
  text-decoration: none;
}
.user_details_box {
  display: flex;
  justify-content: left;
  gap: 20px;
  border-bottom: 1px solid #fff;
}
.wallet_details {
  margin: auto 0;
  text-align: left;
  font-size: 18px;
}
.adresscopy {
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}
.adresscopy:active {
  color: #b9b9b9;
}
.eachItems {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 12px 10px;
  cursor: pointer;
  transition: all ease-in 0.3s;
  border-radius: 9px;
}
.eachItems:hover {
  background-color: #000;
}
.Nav_title {
  display: flex;
  gap: 10px;
}
.redCond {
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px;
  font-size: 13px;
 
line-height: 100%;
  background-color: rgba(199, 58, 51, 1);
  border-radius: 50%;
}
.small {
  font-size: 14px;
  color: #8a8a8a;
}
@media screen and (max-width: 991px) {
  nav .nav__container {
    justify-content: flex-end;
  }
  nav .nav__container ul.mainnav:first-of-type {
    display: none;
  }
}
