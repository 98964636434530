:root {
  --main-color: #181818;
  --primary-color: #c73a33;
  --secondry-color: #ebc43e;
  --text-primary-color: #000000;
  --text-secondry-color: #f8f8f8;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Trap";
  src: url("./assets/fonts/trap/Trap-Black.otf");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Trap";
  src: url("./assets/fonts/trap/Trap-Bold.otf");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Trap";
  src: url("./assets/fonts/trap/Trap-Regular.otf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Trap";
  src: url("./assets/fonts/trap/Trap-SemiBold.otf");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Agharti";
  src: url("./assets/fonts/Agharti-Black.ttf");
  font-weight: 900;
  /* font-display: swap; */
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Trap";
  background-color: #181818;
  color: var(--text-secondry-color);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  /* min-height: 100vh; */
  place-items: center;
  position: relative;
  display: grid;
  padding: 60px 0;
  /* max-width: 1400px; */
}
.addPostion {
  position: absolute;
}

.container {
  padding: 0 60px;
  max-width: 1500px;
  margin: 0 auto;
}
.innerBox {
  display: flex;
  justify-content: center;
  width: 100%;
}
.subhead {
  font-family: "Trap";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 100%;
  text-align: center;
}
.heading {
  font-family: "Agharti";
  font-style: normal;
  font-weight: 900;
  font-size: 75px;
  line-height: 100%;
}
.heading span {
  font-family: "Agharti";
 
}
.text {
  font-family: "Trap";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
}
.small-text {
  font-family: "Trap";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
}
.yellow {
  color: var(--secondry-color);
}
.yellowFade {
  background: #ebc43e;
  filter: blur(350px);
  width: 417px;
  height: 417px;
}
.fullwidth {
  padding: 0 60px;
  max-width: 1600px;
  margin: 0 auto;
}
._v {
  overflow-y: scroll !important;
}
._r {
  overflow: hidden;
}
.pc {
  display: block;
}
.mobile {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1);
  border-radius: 10px;
}



/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Chrome, Safari, and Edge */
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px transparent;
}

/* For Firefox */
input:-moz-autofill {
  -moz-box-shadow: inset 0 0 0px 9999px transparent;
}
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 456px) {
}

@media screen and (max-width: 1310px) {
  .subhead {
    font-family: "Trap";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
  }
  .heading {
    font-family: "Agharti";
    font-style: normal;
    font-weight: 900;
    font-size: 75px;
    line-height: 100%;
  }
  .text {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 130%;
  }
  .small-text {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
  }
  .subhead {
    font-family: "Trap";
    font-style: normal;
    font-weight: 800;
    font-size: 3.43vw;
    line-height: 100%;
    text-align: center;
  }
  .heading {
    font-family: "Agharti";
    font-style: normal;
    font-weight: 900;
    font-size: 75px;
    line-height: 100%;
  }
  .text {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 1.87vw;
    line-height: 130%;
  }
  .small-text {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 140%;
  }
}
@media screen and (max-width: 999px) {
  section {
    min-height: auto;
    place-items: center;
    position: relative;
    display: grid;
    padding: 60px 0;
    /* max-width: 1400px; */
  }
  .subhead {
    font-family: "Trap";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
  }
  .heading {
    font-family: "Agharti";
    font-style: normal;
    font-weight: 900;
    font-size: 62px;
    line-height: 100%;
  }
  .text {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 130%;
  }
  .small-text {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
  }
}

@media screen and (max-width: 768px) {
 
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
  .container {
    padding: 0 20px;
    max-width: 1400px;
    margin: 0 auto;
  }
  section {
    min-height: auto;
    place-items: center;
    position: relative;
    display: grid;
    padding: 40px 0;
  }
  .subhead {
    font-weight: 800;
    font-size: 28px;
    line-height: 100%;
    text-align: center;
  }
  .heading {
    font-weight: 900;
    font-size: 44px;
    line-height: 100%;
    text-align: center;
  }
  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
  }
  .small-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
  }
  .yellowFade {
    background: #ebc43e;
    filter: blur(150px);
    width: 217px;
    height: 217px;
  }
}

.vert-move {
  -webkit-animation: mover 5s infinite alternate;
  animation: mover 5s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
.vert-move-reverse {
  -webkit-animation: moverDown 5s infinite alternate;
  animation: moverDown 5s infinite alternate;
}
@-webkit-keyframes moverDown {
  0% {
    transform: translate(-30px);
  }
  100% {
    transform: translate(0px);
  }
}
@keyframes moverDown {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
