.faqs {
  width: 100%;
  max-width: 878px;
  margin: 0 auto;
  padding: 15px;
  background: rgba(34, 34, 34, 0.6);
  border: 1px solid #c4c4c4;
  border-radius: 18px;
}

.faqs .faq {
  /* width: 100% ; */

  padding: 22px 34px;
  cursor: pointer;
  /* margin-bottom: 32px; */

  /* border: 1px solid #C4C4C4ddd; */
  border-radius: 10px;

  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
}

.faqs .faq .faq-question {
  position: relative;
  width: 100%;
  font-size: 18px;
  /* padding-right: 80px; */
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  /* padding-top: 10px; */
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.dd {
  font-family: "Trap";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  padding-top: 20px;
}
.ddd {
  font-family: "Trap";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  padding-top: 5px;
}
 ul {
  list-style-type: circle;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
.qus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.svg {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 768px) {
  .darrow img {
    width: 12px;
  }
  .faqs .faq {
    padding: 12px 20px;
    /* margin-bottom: 20px; */
    /* min-height: 45px; */
    /* border: 1px solid #C4C4C4; */
    /* border-radius: 10px; */

    /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
  }

  .qus {
    display: flex;
    gap: 15px;
  }
  .svg {
    min-width: 11px;
    height: 11px;
  }
  .faqs {
    max-width: 100%;
    width: auto;
    padding:20px 0;
  }
  .faqs .faq .faq-question {
    position: relative;
    font-size: 14px;
    padding-right: 20px;
    transition: all 0.4s ease;
  }

  .faqs .faq.open .faq-answer {
    max-height: auto;
    opacity: 1;
    font-size: 12px;
  }
  .dd {
    font-family: "Trap";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    padding-top: 20px;
  }
  .ddd {
    font-family: "Trap";
    font-style: normal;
    font-weight: 400;
    font-size:  10px;
    line-height: 140%;
    padding-top: 5px;
  }
  .ddd  li{
    margin-top: 3px;


  }
  
}
