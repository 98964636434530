.climeHead {
  width: 100%;
  margin-bottom: 50px;
}
.headWrapper {
  display: flex;
  justify-content: space-between;
  padding: 60px;
  padding-bottom: 0;
}
.climeHead .right .text {
  max-width: 447px;
  margin-bottom: 30px;
}
.nftPreview {
  width: 100%;
  overflow: hidden;
  position: relative;
  /* margin-bottom: 60px; */
  padding-top: 30px;

}
.bg-wrapperNft {
  /* gap: 60; */
  margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.postion {
  position: absolute;
  top: 600px;
}

.emiwayText {
  font-family: "Agharti";
  font-style: normal;
  font-weight: 900;
  font-size: 24vw;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.14);
  line-break: strict;
}
.nftCard {
  top: -20px;
  position: absolute;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nftCard img {
  width: 600px;
}
.bgwrapper {
  /* background-image: url("../../assets/images/bgwrapper.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 80px;
  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;
  /* background-color: #252525; */
}
.products .cards__container {
  padding-top: 10px;
  /* width: 100%; */
  margin-top: 50px;
  overflow: hidden;
}
.products .cards__container .cards__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 22px 24px;
  /* overflow-x: auto; */
}
.products .cards__container .cards__row::-webkit-scrollbar {
  height: 8px;
  background-color: #3c3c3c;
  border-radius: 4px;
  display: none;
}
.products .cards__container .cards__row::-webkit-scrollbar-thumb {
  background: #000;
}
.btnSec {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.leftcircleOverlay {
  position: absolute;
  left: -150px;
  top: 60px;
  width: 350px;
}
.rightDiaOverlay {
  position: absolute;
  right: -180px;
  bottom: -30px;
  width: 400px;
}
.rightDiaOverlay img {
  width: 100%;
}
.rightsOverlay {
  position: absolute;
  left: 00px;
  top: -140px;
  width: 450px;
}

/*  */
.totalwrapper {
  display: flex;
  margin-top: 0px;
}
.leftSide {
  min-width: 500px;
  position: relative;
}
.postioncard {
  position: absolute;
  top: 40px;
  background: #ebc43e;
  filter: blur(150px);
  left: 100px;
  width: 300px;
}
.dotBoxSmall {
  position: absolute;
  top: -70px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.dropbox {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.dropbox video {
  width: 360px;
}

.rightSide {
  max-width: 800px;
}
.maintTextBox {
  display: flex;
}
.emogyBox {
  font-weight: 800;
  font-size: 75px;
  line-height: 120%;
}
.textsBoxs {
  font-weight: 800;
  font-size: 44px;
  line-height: 120%;
}
.smalltest {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  max-width: 700px;
}
.buttonBox {
  padding-top: 50px;
}
.buttonBox .button {
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  margin: 0 auto;
  margin-top: 16px;
}
.dotTest {
  width: 60px;
}

/*  */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 456px) {
}

@media screen and (max-width: 1450px) {
  .leftSide {
    min-width: 400px;
    position: relative;
  }
  .textsBoxs {
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
  }
  .smalltest {
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
    max-width: 700px;
  }
  .rightDiaOverlay {
    position: absolute;
    right: -100px;
    bottom: -50px;
    width: 250px;
  }
  .rightsOverlay {
    position: absolute;
    left: 80px;
    top: -30px;
    width: 290px;
  }
  .nftCard {
    /* top: 100px; */
    position: absolute;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nftCard img {
    /* width: 37vw; */
    width: 500px;
  }
  .dropbox {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .dropbox video {
    width: 300px;
  }
}
.dotTest {
  width: 60px;
}
@media screen and (max-width: 991px) {
  .products .cards__container .cards__row {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    width: 100%;
    gap: 22px 13px;
    /* overflow-x: auto; */
  }
  .climeHead .right .text {
    max-width: 360px;
    margin-bottom: 0px;
  }
  .climeHead{
  margin-bottom: 30px;
  
  
  }
  .totalwrapper {
    display: flex;
    flex-direction: column;
  }
  .dotTest {
    width: 50px;
  }
  .nftPreview {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
  }
  .dotBoxSmall{
  display: none;
  }
  .bg-wrapperNft {
    /* gap: 60; */

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .nftCard {
    /* top: 60px; */
    position: absolute;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nftCard img {
    width: 550px;
  }

  .leftcircleOverlay {
    left: -150px;
    top: 60px;
    width: 250px;
  }
  .rightDiaOverlay {
    right: -130px;
    bottom: 0px;
    width: 300px;
  }
  .rightsOverlay {
    position: absolute;
    left: 00px;
    top: -90px;
    width: 300px;
  }
}

@media screen and (max-width: 760px) {
  .products .cards__container {
    padding-top: 0px;
    width: 100%;
    overflow: hidden;
    margin-top: 40px;
  }
  .products .cards__container .cards__row {
    display: grid;
    grid-template-columns:  1fr 1fr;
    width: 100%;
    gap: 22px 13px;
    /* overflow-x: auto; */
  }
  .dotTest {
    width: 37px;
  }
  .bgwrapper {

    margin-top: 20px;
  }
  .headWrapper {
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding: 0 40px;
    padding-top: 80px;
  }
  .nftPreview {
    position: relative;
    margin-bottom: 20px;
  }
  .nftCard {
    top: 20px;
    position: absolute;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nftCard img {
    width: 85%;
  }
  .bg-wrapperNft {
    /* gap: 60; */
    margin-top: 80px;
  }
  .rightDiaOverlay {
    display: none;
  }
  .rightsOverlay {
    display: none;
  }
  .leftcircleOverlay {
    position: absolute;
    left: -80px;
    top: 120px;
    width: 180px;
  }
  /*  */
  .totalwrapper {
    display: flex;
    flex-direction: column;
  }
  .leftSide {
    min-width: auto;
    position: relative;
  }
  .postioncard {
    position: absolute;
    top: 40px;
    background: #ebc43e;
    filter: blur(100px);
    left: 30%;
    width: 150px;
    height: 150px;
  }
  .dotBoxSmall {
  display: block;

    position: absolute;
    top: 60px;
    left: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .dropbox {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .rightSide {
    max-width: 800px;
  }
  .maintTextBox {
    display: flex;
  }
  .emogyBox {
    font-weight: 800;
    font-size: 75px;
    line-height: 120%;
  }
  .textsBoxs {
    
    font-weight: 800;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    max-width: 370px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .smalltest {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    max-width: 700px;
    margin-top: 30px;
    text-align: center;
    max-width: 350px;
  }
  .buttonBox {
    padding-top: 0px;
  }
  .buttonBox .button {
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    margin: 0 auto;
    margin-top: 16px;
    width: 240px;
    font-size: 16px;
    line-height: 18px;
  }
}
